
import Snackbar from 'node-snackbar';
import { translate } from './translates.js';

export default function (text) {
    return Snackbar.show({
        pos: "top-center",
        text: text,
        actionText: translate('Закрыть'),
        actionTextColor: "#fdbf88",
        backgroundColor: "#6739bb"
    });
}