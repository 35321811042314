import Vue from 'vue'

import Youtube from '../components/youtube/youtube.vue'

import '../components/header/index.js'
import '../components/contact_form/index.js'

var tabs_view = new Vue({
    el: '#tabs-view',
    data: {
        'show_author': false,
    },
    methods: {
        update_tab: function (show_author) {
            this.show_author = show_author
        }
    }
})


var youtube_video = new Vue({
    el: '#youtube-video',
    template: '<Youtube :youtube="youtube"/>',
    components: {
        Youtube
    },
    data: {
        youtube: {
            url: null,
            alt: '',
            placeholder_src: '',
            show: false
        }
    },
    beforeMount: function () {
        if (this.$el.hasAttribute('data-youtubeLink')) {
            this.youtube.url = this.$el.getAttribute('data-youtubeLink');
            this.youtube.show = true
        }
        if (this.$el.hasAttribute('data-youtubeAlt')) {
            this.youtube.alt = this.$el.getAttribute('data-youtubeAlt');
        }
        if (this.$el.hasAttribute('data-youtubePlaceholderSrc')) {
            this.youtube.placeholder_src = this.$el.getAttribute('data-youtubePlaceholderSrc');
        }
    }
})