<template>
  <div v-if="youtube.show">
    <LazyYoutubeVideo :alt="youtube.alt" :url="youtube.url" previewImageSize="hqdefault" />
  </div>
  <div v-else>
    <img :src="youtube.placeholder_src" alt="Видео скоро будет" />
  </div>
</template>


<script>
import LazyYoutubeVideo from "vue-lazy-youtube-video";

export default {
  name: "YoutubeVideoBlock",
  props: ["youtube"],
  components: {
    LazyYoutubeVideo
  }
};
</script>