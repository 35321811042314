var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vodal",
    {
      staticClass: "submit_form_modal",
      attrs: {
        show: _vm.show,
        animation: "fade",
        customStyles: { width: "auto", height: "auto" }
      },
      on: { hide: _vm.closeModal }
    },
    [
      _c("img", {
        attrs: { src: "/static/images/modals/icon_success.svg", alt: "success" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-title" }, [
        _vm._v(_vm._s(_vm.translate("Мы скоро свяжемся с вами!")))
      ]),
      _vm._v(" "),
      _vm.translate("ru") === "ru"
        ? _c("div", { staticClass: "expected-time-label" }, [
            _c("span", { staticClass: "response" }, [
              _vm._v(
                _vm._s(_vm.text) +
                  " " +
                  _vm._s(_vm.translate("Ответим вам в течение 5 минут"))
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }