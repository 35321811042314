<template>
  <vodal
    :show="show"
    animation="fade"
    @hide="closeModal"
    class="service_modal"
    v-bind:customStyles="{width: 'auto', height: 'auto'}"
  >
    <div class="modal-content-block">
      <div class="form-block">
          <div class="title">{{content.name}}<div class="icon" :style="`background-image:url(${content.icon})`"/></div>
          <div class="description">{{content.description}}</div>
          <ServiceForm :service="content.name" :contact_phone="contact_phone"/>
      </div>
      <div class="steps-block">
        <div v-for="(step,index) in content.steps_description" :key="index" class="step">
          <div class="step-number">
            {{index+1}}
          </div>
          <span class="step-text">{{step}}</span>
        </div>
      </div>
    </div>
  </vodal>
</template>

<script>

import Vue from "vue";
import Vodal from "vodal";
import ServiceForm from './components/service_form.vue'

import { translate } from "../../translates.js";

Vue.component(Vodal.name, Vodal);

export default {
    name:'ServiceModal',
    props:["show","content", "contact_phone"],
    // data(){
    //     return{ 

    //     }
    // },
    methods: {
        translate: function(msg) {
            return translate(msg);
        },
        closeModal: function() {
            this.$emit("closed", true);
        },
    },
    components:{
      ServiceForm
    }
    
}
</script>
