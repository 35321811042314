var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.youtube.show
    ? _c(
        "div",
        [
          _c("LazyYoutubeVideo", {
            attrs: {
              alt: _vm.youtube.alt,
              url: _vm.youtube.url,
              previewImageSize: "hqdefault"
            }
          })
        ],
        1
      )
    : _c("div", [
        _c("img", {
          attrs: { src: _vm.youtube.placeholder_src, alt: "Видео скоро будет" }
        })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }