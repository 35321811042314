import axios from 'axios'

export default function getAverageLinkPrice(link) {
	let reqeustLink = window.__app.get_average_link_price ? window.__app.get_average_link_price : "/get-average-price/";
	let requestConfig = {
		method: 'post',
		url: reqeustLink,
		data: {
			link: link,
		}
	}
	return axios(requestConfig).then(function (response) {
		return response.data
	});
}

export function addHttp(url) {
	const isProtocoledRepExp = new RegExp('(f|ht)tps?://');
	if (!isProtocoledRepExp.test(url) && url !== '') {
		url = "http://" + url
	}
	return url
}

export function isUrl(value) {
	const expression = (/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i)
	const result = value.match(expression)
	return result
}

export function getUuid() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}

export function isEmail(email) {
	var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	email = email.trim()
	return re.test(String(email).toLowerCase());
}