var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox-block orange" }, [
    _c("input", {
      attrs: {
        type: "checkbox",
        name: "privacy_agreement",
        id: "privacy_agreement_modal_offline_" + _vm.uuid,
        checked: "checked",
        required: ""
      }
    }),
    _vm._v(" "),
    _c(
      "label",
      { attrs: { for: "privacy_agreement_modal_offline_" + _vm.uuid } },
      [
        _vm._v(
          "\n    " + _vm._s(_vm.translate("Отправляя форму, я даю")) + "\n    "
        ),
        _c(
          "a",
          {
            attrs: {
              href: _vm.translate(
                "/coglasie-na-obrabotku-personalnyih-dannyih/"
              ),
              target: "_blank"
            }
          },
          [
            _vm._v(
              _vm._s(_vm.translate("согласие на обработку персональных данных"))
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }