<template>
  <div>
    <form class="form offline-form" id="contact-form" v-on:submit.prevent="submitForm">
      <input type="hidden" ref="formname" data-value="Форма на главной" name="formname" />
      <div class="form-content">
        <input
          type="text"
          v-model="form.contact"
          class="textfield"
          maxlength="256"
          name="contact"
          :placeholder="translate('Email или телефон')"
          required="required"
          v-if="LOCALE == 'ru'"
        />

        <div class="form-input phone-input" v-else>
          <VueTelInput
            :inputId="`phone_input`"
            :mode="'international'"
            :dynamicPlaceholder="true"
            :required="true"
            v-model="form.contact"
          />
        </div>
        <button
          type="submit"
          :disabled="is_submit"
          class="orange-button button button-outline"
        >{{translate('Отправить')}}</button>
      </div>
      <div class="checkbox-block orange">
        <input
          type="checkbox"
          name="form_offer_agreement"
          id="form_offer_agreement"
          checked="checked"
          required
        />
        <label for="form_offer_agreement">
          {{translate('Отправляя форму, я даю')}}
          <a
            :href="translate('/politika-obrabotki-personalnyih-dannyih/')"
            target="_blank"
          >{{translate('согласие на обработку персональных данных')}}</a>
        </label>
      </div>
    </form>
    <SubmitFormModal :show="show" :text="modal_text" @closed="closeModal" />
  </div>
</template>

<script>
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";

import qs from "qs";
import axios from "axios";

import SubmitFormModal from "../modals/submit_form_modal.vue";
import { translate } from "../../translates.js";
import Snackbar from "../../snackbar.js";

Vue.use(VueTelInput);

export default {
  name: "ContactForm",
  data: function() {
    return {
      LOCALE: LOCALE,
      show: false,
      form: {
        csrfmiddlewaretoken: window.__app.csrf_token,
        contact: "",
        formname: ""
      },
      is_submit: false,
      modal_text: ""
    };
  },

  components: {
    SubmitFormModal,
    VueTelInput
  },
  methods: {
    translate: function(msg) {
      return translate(msg);
    },
    openModal: function(text) {
      this.show = true;
      this.modal_text = text;
    },
    closeModal: function() {
      this.show = false;
      this.modal_text = "";
    },
    submitForm: async function() {
      this.is_submit = true;
      let formData = this.form;
      if (window.__app.recaptcha_enabled) {
        const rtk = await window.__app.recaptcha_reset();
        formData = { ...formData, rtk }; // Object.assign({ rtk: window.__app.rtk }, formData); //
      }

      const data = qs.stringify(formData),
        url = window.__app.submit_client_form;

      // SubmitFormModal.openModal("text");
      axios
        .post(url, data)
        .then(response => {
          let currentLocation = window.location;
          window.ym && ym(window.ya_id, "reachGoal", "removingAnyFormSubmit");
          window.gtag &&
            gtag("event", "request", {
              event_category: "any"
            });
          if (currentLocation.pathname === "/author_compensation/") {
            window.ym && ym(window.ya_id, "reachGoal", "authorRequest");
            window.gtag &&
              gtag("event", "request", {
                event_category: "author"
              });
          } else {
            window.ym && ym(window.ya_id, "reachGoal", "clientRequest");
            window.gtag &&
              gtag("event", "request", {
                event_category: "client"
              });
          }
          this.openModal(response.data.result);
        })
        .catch(error => {
          Snackbar(translate("Извините, произошла ошибка, попробуйте еще раз"));
        })
        .finally(() => {
          this.is_submit = false;
        });
    }
  },
  mounted() {
    this.form.formname = this.$refs.formname.dataset.value;
  }
};
</script>

