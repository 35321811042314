var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vodal",
    {
      staticClass: "service_modal",
      attrs: {
        show: _vm.show,
        animation: "fade",
        customStyles: { width: "auto", height: "auto" }
      },
      on: { hide: _vm.closeModal }
    },
    [
      _c("div", { staticClass: "modal-content-block" }, [
        _c(
          "div",
          { staticClass: "form-block" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.content.name)),
              _c("div", {
                staticClass: "icon",
                style: "background-image:url(" + _vm.content.icon + ")"
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _vm._v(_vm._s(_vm.content.description))
            ]),
            _vm._v(" "),
            _c("ServiceForm", {
              attrs: {
                service: _vm.content.name,
                contact_phone: _vm.contact_phone
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "steps-block" },
          _vm._l(_vm.content.steps_description, function(step, index) {
            return _c("div", { key: index, staticClass: "step" }, [
              _c("div", { staticClass: "step-number" }, [
                _vm._v("\n          " + _vm._s(index + 1) + "\n        ")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "step-text" }, [_vm._v(_vm._s(step))])
            ])
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }