import Vue from 'vue'
import axios from 'axios'

import ServiceModal from '../modals/service_modal.vue'

var navbar = new Vue({
    el: '#navbarMain_v2',
    name: "Header",
    data() {
        return {
            showNav: false,
            modals: {
                service_modal: {
                    show: false,
                    selected_service_id: null,
                    content: {}
                }
            }
        }
    },
    components: {
        ServiceModal,
    },
    methods: {
        openModal: function (service) {
            const oldService = this.modals.service_modal.selected_service_id
            if (oldService == service) {
                this.modals.service_modal.show = true;
                return
            }
            this.modals.service_modal.selected_service_id = service;

            axios.get(`/api/v1/services/${service}/`).then((result) => {
                let data = result.data;
                data.steps_description = data.steps_description.split('@').filter((el) => (el != ''))
                this.modals.service_modal.content = data;
                console.log(data);
                this.modals.service_modal.show = true;
            })

        },
        closeModal: function () {
            this.modals.service_modal.show = false;
        },
    }
});