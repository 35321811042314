var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "service-form",
          attrs: { method: "post" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("div", { staticClass: "form-input" }, [
            _c("label", { attrs: { for: "contact_field_" + _vm.uuid } }, [
              _vm._v(_vm._s(_vm.translate("Как с вами связаться?")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.contact,
                  expression: "form.contact"
                }
              ],
              staticClass: "service-form-input",
              attrs: {
                type: "text",
                maxlength: "256",
                name: "contact",
                id: "contact_field_" + _vm.uuid,
                required: ""
              },
              domProps: { value: _vm.form.contact },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "contact", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("PrivacyCheckbox"),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-orange",
              class: { "is-loading": _vm.is_submit },
              attrs: {
                type: "submit",
                disabled: _vm.is_submit || !_vm.form.contact
              }
            },
            [_vm._v(_vm._s(_vm.translate("Оставить заявку")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "contact-phone-block" }, [
        _c("span", { staticClass: "phone-label" }, [
          _vm._v(
            _vm._s(_vm.translate("Либо вы позвоните. Сами всё расскажем."))
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "phone-number" }, [
          _vm._v(_vm._s(_vm.contact_phone))
        ])
      ]),
      _vm._v(" "),
      _c("SubmitFormModal", {
        attrs: { show: _vm.showSubmitSuccessForm, text: _vm.submitSuccessText },
        on: { closed: _vm.closeSubmitSuccssModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }