<template>
  <div class="checkbox-block orange">
    <input
      type="checkbox"
      name="privacy_agreement"
      :id="`privacy_agreement_modal_offline_${uuid}`"
      checked="checked"
      required
    />
    <label :for="`privacy_agreement_modal_offline_${uuid}`">
      {{translate('Отправляя форму, я даю')}}
      <a
        :href="translate('/coglasie-na-obrabotku-personalnyih-dannyih/')"
        target="_blank"
      >{{translate('согласие на обработку персональных данных')}}</a>
    </label>
  </div>
</template>

<script>
import { translate } from "../../../../translates.js";
import { getUuid } from "../../../../utils.js";
export default {
  name: "PrivacyCheckbox",
  data() {
    return {
      uuid: getUuid()
    };
  },
  methods: {
    translate: function(msg) {
      return translate(msg);
    }
  }
};
</script>
