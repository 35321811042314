<template>
  <vodal
    :show="show"
    animation="fade"
    @hide="closeModal"
    class="submit_form_modal"
    v-bind:customStyles="{width: 'auto', height: 'auto'}"
  >
    <img src="/static/images/modals/icon_success.svg" alt="success" />
    <div class="modal-title">{{translate('Мы скоро свяжемся с вами!')}}</div>
    <div class="expected-time-label" v-if="translate('ru') === 'ru'">
      <span class="response">{{text}} {{translate('Ответим вам в течение 5 минут')}}</span>
    </div>
  </vodal>
</template>

<script>
import Vue from "vue";
import Vodal from "vodal";

import { translate } from "../../translates.js";

Vue.component(Vodal.name, Vodal);

export default {
  name: "SubmitFormModal",
  props: ["show", "text"],
  methods: {
    translate: function(msg) {
      return translate(msg);
    },
    closeModal: function() {
      this.$emit("closed", true);
    }
  }
};
</script>
