<template>
<div>
    <form class="service-form" method="post" v-on:submit.prevent="submitForm">
        <div class="form-input"> 
            <label :for="`contact_field_${uuid}`">{{translate('Как с вами связаться?')}}</label>
                <input
                type="text"
                class="service-form-input"
                maxlength="256"
                v-model="form.contact"
                name="contact"
                :id="`contact_field_${uuid}`"
                required
            />
            <!-- :placeholder="translate('Как с вами связаться? ')" -->
        </div>
        <PrivacyCheckbox/>
        <button
            type="submit"
            class="button is-orange"
            :disabled="is_submit || !form.contact"
            :class="{'is-loading':is_submit}"
        >{{translate('Оставить заявку')}}</button>
    </form>
    <div class="contact-phone-block">
        <span class="phone-label">{{translate('Либо вы позвоните. Сами всё расскажем.')}}</span>
        <div class="phone-number">{{contact_phone}}</div>
    </div>
    <SubmitFormModal
        :show="showSubmitSuccessForm"
        :text="submitSuccessText"
        @closed="closeSubmitSuccssModal"
    />
</div>
</template>

<script>

import axios from "axios";
import qs from "qs";

import { translate } from "../../../translates.js";
import { getUuid } from "../../../utils.js";

import PrivacyCheckbox from './form_components/privacy_checkbox.vue'
import SubmitFormModal from "../submit_form_modal.vue";
import Snackbar from "../../../snackbar.js";

export default {
    name:'ServiceForm',
    props: ["service", "contact_phone"],
    data(){
        return{
            is_submit:false,
            showSubmitSuccessForm: false,
            submitSuccessText: "",      
            uuid: getUuid(),
            form:{
                csrfmiddlewaretoken: window.__app.csrf_token,
                contact:'',
            },
        }
    },
    methods:{
        translate: function(msg) {
            return translate(msg);
        },
        submitForm: async function() {
            this.is_submit = true;

            let formData = this.form;
            if (window.__app.recaptcha_enabled) {
                const rtk = await window.__app.recaptcha_reset();
                formData = { ...formData, rtk };
            }
            formData['formname'] = this.service;
            
            const data = qs.stringify(formData),
                  url = window.__app.submit_client_form;

            axios
            .post(url, data)
            .then(response => {
                // window.ym && ym(window.ya_id, "reachGoal", "removingAnyFormSubmit");
                window.ym && ym(window.ya_id, "reachGoal", "offlineSubmit");
                window.gtag &&
                gtag("event", "request", {
                    event_category: "any"
                });
                window.gtag &&
                    gtag("event", "submitLeadForm", {
                    event_category: "Leads"
                });
                this.openSubmitSuccessModal(response.data.result);
            })
            .catch(error => {
                Snackbar(translate("Извините, произошла ошибка, попробуйте еще раз"));
            })
            .finally(() => {
                this.is_submit = false;
            });
        },
        openSubmitSuccessModal: function(text) {
            this.submitSuccessText = text;
            this.showSubmitSuccessForm = true;
        },
        closeSubmitSuccssModal: function() {
            this.showSubmitSuccessForm = false;
        }
    },
    components:{
        SubmitFormModal,
        PrivacyCheckbox
    }
    
}
</script>
